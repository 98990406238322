const routes = [
    {
        path: "/esl/course",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "course_index"
        }
    }
]

export default routes;
