const routes = [
    {
        path: "/tenant/reservations/list/:token",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: false,
            permission: ''
        }
    }
];

export default routes;
