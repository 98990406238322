import Api from '@/services/Index';

// Reservation
const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/reservations/list', config);
}

const studentToken = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/reservations/student/token');
}

export default {
    getAll,
    studentToken
}
