import HousingReservationsListService from '@/services/HousingReservationsListService'

const module = {
    namespaced: true,
    state: () => ({
        token: null,
    }),
    getters: {
        getReservationToken(state) {
            return  JSON.parse(localStorage.getItem('student_reservation_token'))
        },
        
    },
    mutations: {

        // Token
        setReservationToken(state, token) {
            state.token = token;
        },
        setUser(state, user) {
            state.user = user;
        },
        // Clear
        clearUserAndToken(state) {
            state.user = {}
            state.token = null;
        },

    },
    actions: {
        async setReservationToken({commit}) {
            await HousingReservationsListService.studentToken()
                             .then(response => {
                                const token =response.data.data.token
                                commit('setReservationToken',token)
                                //  let data = response.data.data;
                                 localStorage.setItem('student_reservation_token',JSON.stringify(token))
                             })
        },
    }
}

export default module;
