import Base from '@/plugins/Base';
const module = {
    namespaced: true,
    state: () => ({
        formData: {},
    }),
    getters: {
        formData(state) {
            return state.formData;
        },
    },
    mutations: {
        setFormData(state, formData) {
            Base.LocalStorage.set('enrollmentFormData', formData);
            state.formData = formData;
        },
        clearFormData(state) {
            Base.LocalStorage.remove('enrollmentFormData');
            state.formData = {};
        },
    },
    actions: {
       async saveFormData({ commit }, formData) {
            await commit('setFormData', formData);
        },
        async deleteFormData({ commit }) {
           await commit('clearFormData');
        },
    },
};


export default module;
