<template>
    <div>
        <v-date-picker
            v-model="selected"
            :locale="'en'"
            :mode="isTime ? 'dateTime' : ''"
            @input="handleInput"
            :is24hr="isTime"
            is-expanded
        >
            <template v-slot="{ inputValue, inputEvents }" >
                <b-input-group :class="validationError ? 'date-validation-error' : ''">
                    <input
                        class="form-control"
                        :readonly="readonly"
                        :value="inputValue"
                         v-on="!disabled?inputEvents:''"
                        :placeholder="setFormatShow()"
                     />
                    <b-input-group-append>
                        <b-button variant="outline-secondary" class="btn-40" :disabled="disabled">
                            <i class="ri-calendar-line"></i>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback v-if="validationError" v-html="validationError" />
            </template>
        </v-date-picker>

    </div>
</template>

<script>
import moment from "moment";
export default {
    props: {
        value: {
            default: null
        },
        validationError: {
            default: false
        },
        isTime: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: true
        },
        disabled:{
          type:Boolean,
          default:false
        }
    },
    watch: {
        value: function (newValue, oldValue) {
            if (newValue != oldValue) {
                this.selected = newValue
            }
        }
    },
    data() {
        return {
            selected: null
        }
    },
    methods: {
        setFormatShow() {
            return this.isTime ? 'mm/dd/yyyy --:--' : 'mm/dd/yyyy'
        },
        handleInput(event) {
            let value = null;
            if (event) {
                if (this.isTime) {
                    value = moment(event).format("MM/DD/YYYY HH:mm:ss")
                }
                else {
                    value = moment(event).format("MM/DD/YYYY")
                }
            }
            this.$emit("input", value)
        }
    },
    created() {
        this.selected = this.value
    }
}
</script>
<style scoped>
.date-validation-error {
    border: 1px solid #ff9eae;
    border-radius: 0.375rem;
}

</style>
